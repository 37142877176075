import React from 'react'

function useColumns(children) {
	if (children && Array.isArray(children)) {
		const length = children.length
		const hasRemainder = length % 2 > 0
		const divisible = hasRemainder ? children.filter((_, i) => i < length - 1) : children
		const odd = divisible.filter((_, i) => i % 2)
		const even = divisible.filter((_, i) => !(i % 2))
		const remainder = hasRemainder ? children[length - 1] : null
		return [odd, even, remainder]
	} else if (children) {
		return [null, null, children]
	} else {
		return []
	}
}

export function Columns({ children }) {
	const [odd, even, remainder] = useColumns(children) 
	return (
		<>
			<div className="columns-single-layout">
				{React.Children.map(children, (child) => child)}
			</div>
			{odd && even ? (
				<div className="columns-layout">
					{React.Children.map(even, (child) => child)}
					{React.Children.map(odd, (child) => child)}
					{remainder}
				</div>
			) : remainder && (
				<div className="columns-remainder">
					{remainder}
				</div>
			)}
		</>
	)
}