import React from 'react'
import Link from '../components/link'
import { GatsbyImage, MissingImage } from '../components/image' 

export default function ArticleCard({ 
	title, 
	subtitle, 
	link,
	cover,
	description
}) {
	return (
		<div className="article-card mbx4">
			<Link to={link} className="article-card-image">
				{cover?.large ? (
					<GatsbyImage data={cover?.large} />
				) : (
					<MissingImage />
				)}
			</Link>
			<div className="article-card-info">
				<Link to={link}><h1>{title}</h1></Link>
				<em>{subtitle}</em>
				<p className="outerx2">{description?.text}</p>
			</div>
		</div>
	)
} 