import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Main } from '../wrappers/layout'
import ArticleCard from '../components/article-card'
import { Columns } from '../components/article-list'

export default function Stories({ data }) {
	
	const { articles, backgroundColor } = data?.page || {}

	return (
		<Layout backgroundColor={backgroundColor}>
			<Main className="outerx2 page-gutters">
				<div className="max-xl mauto">
					<Columns>
						{articles.map((article, i) => 
							<ArticleCard 
								{...article} 
								link={`/${article.category.slug}/${article.slug}`}
								key={`${article.slug}${i}test`}
							/>
						)}
					</Columns>
				</div>
			</Main>
		</Layout>
	)
}

export { StoriesMeta as Head } from "../components/meta"

export const query = graphql`

	fragment ArticleCard on ContentfulArticle {
		id
		title
		slug
		subtitle
		category {
			slug
		}
		cover {
			...ContentfulMedia
		}
		description {
			text: description
		}
	}

  query($slug: String!) {
		page: contentfulStories(slug: { eq: $slug }) {
			articles {
				...ArticleCard
			}
			backgroundColor
		}
  }
`